<template>
  <VueFinalModal v-model="isVisible"
                 class="flex justify-center items-center confirm-modal overflow-scroll"
                 content-class="flex flex-col max-w-xl mx-auto p-4 dark:bg-gray-900 dark:border-gray-700 rounded-lg space-y-2 confirm-modal-content vh-100"
                 :hide-overlay="true"
                 overlay-transition="vfm-fade"
                 content-transition="vfm-fade"
  >
    <template #default>
      <div v-if="isLoading" class="loading-overlay">
        <vue3-lottie ref="lottie"
                     :animationData="animationData"
                     height="60vh"
                     width="100%"
        />
      </div>
      <div :class="'bg-gradient-' + color + ' shadow-' + color" class="card-header d-flex justify-content-start card-header-top">
        <DocumentationLink
            popoverTitle="Expert Creation"
            class="z-index-5"
            description="Learn how to create an Expert"
            docLink="https://docs.b-bot.space/essentials/create-expert"
        />
        <h5 class="modal-title text-white mx-5">Create B-Bot Me</h5>

        <button type="button" class="close-btn btn text-white d-none" @click="closeModal">
          <i class="material-icons-round opacity-10 fs-5">close</i>
        </button>
      </div>
      <div class="modal-scrollable-content p-0 pb-5">

        <div class="steps-indicator d-flex justify-content-center align-items-center">
          <span
              v-for="step in 6"
              :key="step"
              class="d-flex align-items-center justify-content-center"
              :disabled="currentStep !== step"
              :class="{'badge badge-circle badge-success': currentStep === step, 'badge badge-circle badge-dark': currentStep !== step}"
              @click="currentStep = step"
              style="margin: 0 4px;"
          >
            {{ step }}
          </span>
        </div>
        <transition name="slide-fade" mode="out-in">
          <div :key="currentStep">
            <!-- Business Card -->
            <div class="p-3" v-if="currentStep === 1">
              <Walkthrough
                  :steps="walkthroughStepsFirst"
                  :walktrough-activated="true"
              />
              <h1 class="text-center">
                Basic Information
                <DocumentationLink
                    popoverTitle="Basic Information"
                    class="z-index-5 documentation-link"
                description="Learn how to create the Basic information of an Expert"
                docLink="https://docs.b-bot.space/essentials/create-expert#start-to-create-your-first-expert"
                />
              </h1>

              <div class="mb-3" id="proModeContainer">
                <material-switch
                    id="proMode"
                    class="ps-0 ms-0"
                    name="flexSwitchCheckDefault"
                    label-class="mb-0 text-body text-truncate w-80"
                    v-model="proMode"
                    >
                  Pro Mode
                </material-switch
                >
              </div>
              <div class="mb-3">
                <material-input
                    id="name"
                    type="text"
                    label="Name"
                    name="name"
                    size="lg"
                    v-model="expertData.attributes.name"
                />
                <small class="form-text text-muted w-100">Add a name for your Virtual Expert</small>

              </div>

              <div class="mb-3">
                <material-input
                    id="profession"
                    type="text"
                    label="Profession"
                    name="profession"
                    size="lg"
                    v-model="expertData.attributes.profession"
                />
                <small class="form-text text-muted w-100">Add a profession for your Virtual Expert</small>

              </div>

              <div class="mb-3" id="description">
                <material-textarea
                    v-model="expertData.attributes.description"
                    placeholder="Description"
                    class="form-control"
                    id="description-textarea"
                    rows="3"
                    :max-chars="250"
                ></material-textarea>
                <small class="form-text text-muted w-100">Add a description for your Virtual Expert</small>
              </div>
              <h2 v-if="proMode">Manual Creation</h2>
              <div class="mb-3" v-if="proMode" id="systemMessageContainer">
                <material-textarea
                    v-model="expertData.attributes.systemMessage"
                    placeholder="System Message"
                    class="form-control"
                    id="systemMessage"
                    rows="3"
                    :max-chars="250"
                ></material-textarea>
                <small class="form-text text-muted w-100">Add a System Message for your Virtual Expert</small>
              </div>
              <div class="mb-3" v-if="proMode" id="functionNameContainer">
                <material-input
                    v-model="expertData.attributes.functionName"
                    label="function Name"
                    class=""
                    id="functionName"
                    rows="3"
                    :max-chars="250"
                ></material-input>
                <small class="form-text text-muted w-100">Add a Function Name for your Virtual Expert</small>
              </div>
              <div class="mb-3" id="functionDescriptionContainer" v-if="proMode">
                <material-textarea
                    v-model="expertData.attributes.functionDescription"
                    placeholder="Function Description"
                    class="form-control"
                    id="fdescription"
                    rows="3"
                    :max-chars="250"
                ></material-textarea>
                <small class="form-text text-muted w-100">Add a function Description for your Virtual Expert</small>
              </div>

            </div>
            <div v-if="currentStep === 2">
              <Walkthrough
                  :steps="walkthroughStepsSecond"
                  :walktrough-activated="true"
              />
              <h1 class="text-center">
                Profile Picture
                <DocumentationLink
                    popoverTitle="Profile Picture"
                    class="z-index-5 documentation-link"
                    description="Learn how to a Profile Picture to an Expert"
                    docLink="https://docs.b-bot.space/essentials/create-expert#add-a-profile-picture"
                />
              </h1>

              <div class="card shadow-sm mb-4">
                <div class="card-body">
                  <div class="text-center">
                    <div class="position-relative d-inline-block mb-3" id="uploadPictureContainer">
                      <MaterialAvatar
                          :img="selectedExpert.attributes?.profile_picture?.attributes?.url || 'https://t4.ftcdn.net/jpg/04/30/11/17/360_F_430111702_DcBX4q0VE9CZZzyMG42FzoXHdHwM7SfA.jpg'"
                          alt="Profile Picture"
                          size="xl"
                          shadow="sm"
                          :circular="true"
                      />
                      <label for="profilePictureUpload" class="btn btn-sm btn-primary position-absolute bottom-75 start-100 translate-middle-x camera-spaces">
                        <i class="fas fa-camera camera-fontsize"></i>
                      </label>
                    </div>
                    <input type="file" id="profilePictureUpload" @change="handleProfilePictureUpload" class="d-none" accept="image/*" />
                    <p class="text-muted small mt-2">For best results, upload a square image (1:1 aspect ratio)</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Abilities -->
            <div v-if="currentStep === 3">
              <Walkthrough
                  :steps="walkthroughStepsThird"
                  :walktrough-activated="true"
              />
              <h1 class="text-center">
                Abilities
                <DocumentationLink
                    popoverTitle="Abilities"
                    class="z-index-5 documentation-link"
                    description="Learn how to add Abilities to your Expert"
                    docLink="https://docs.b-bot.space/essentials/create-expert#add-abilities"
                />
              </h1>

              <div class="card-body">
                <!-- Check if there are abilities -->
                <div v-if="selectedExpert.attributes?.abilities?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(ability, index) in selectedExpert.attributes.abilities" :key="index">
                      <div class="card p-3" v-if="!ability.isEditing">
                        <p>{{ ability.attributes.text }}</p>

                        <button @click="ability.isEditing = true" class="btn btn-sm btn-secondary mr-button mb-0 ml-0 mt-3">Edit</button>
                        <button @click="deleteAbility(index)" class="btn btn-sm btn-danger mb-0 mt-3">Delete</button>
                      </div>
                      <div class="card p-3" v-else>
                        <material-textarea v-model="ability.attributes.text" class="form-control mb-2" id="ability-textarea" />
                        <button @click="handleSaveAbility(ability, index)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateAbility" class="btn btn-info">Generate new Ability</button>
                </div>
                <div v-else class="text-center" id="generateInitialAbility">
                  <div class="alert alert-info text-center mt-4" role="alert">
                    <h5 class="alert-heading">Add your first Ability</h5>
                    <p class="mb-2">
                      No abilities have been added yet. Abilities help to describe what your expert can do. Click the button below to generate initial abilities.
                    </p>
                  </div>
                  <button @click="generateAbility" class="btn bg-gradient-info">Generate Initial Ability</button>
                </div>
              </div>
              <div class="container p-0 text-center d-none">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Abilities!</h1>

                    <h2 style="color: white">Create Abilities for your Expert</h2>
                    <p>
                      You can steer the Experts behaviours in different situations by providing abilities. Abilities are predefined actions that the expert can take. Click the button below to generate initial abilities.
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toAbilitiesDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <!-- Templates -->
            <div v-if="currentStep === 4">
              <Walkthrough
                  :steps="walkthroughStepsFourth"
                  :walktrough-activated="true"
              />
              <h1 class="text-center">
                Templates
                <DocumentationLink
                    popoverTitle="Templates"
                    class="z-index-5 documentation-link"
                    description="Learn how to add Templates to your Expert"
                    docLink="https://docs.b-bot.space/essentials/create-expert#add-templates"
                />
              </h1>

              <div class="card-body">
                <!-- Check if there are templates -->
                <div v-if="selectedExpert.attributes.templates?.length > 0">
                  <div class="row mb-3">
                    <div class="p-3 col-12 col-md-4 col-lg-4" v-for="(template, index) in selectedExpert.attributes.templates" :key="index">
                      <div class="card p-3" v-if="!template.isEditing">
                        <div>{{ template.attributes.template_text }}</div>
                        <button @click="template.isEditing = true" class="btn btn-sm bg-gradient-secondary mr-button">Edit</button>
                        <button @click="deleteTemplate(index)" class="btn btn-sm bg-gradient-danger">Delete</button>
                      </div>
                      <div class="card p-3" v-else>
                        <material-textarea v-model="template.attributes.template_text" class="form-control mb-2"  id="textarea-template"/>
                        <button @click="saveTemplate(template, index)" class="btn btn-sm bg-gradient-primary">Save</button>
                      </div>
                    </div>
                  </div>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Add new Template</button>
                </div>
                <!-- Show explanation and generate button if no templates -->
                <div v-else class="text-center" id="generateInitialTemplate">
                  <div class="alert alert-info text-center mt-4" role="alert">
                    <h5 class="alert-heading">Add your first Template</h5>
                    <p class="mb-2">
                      No templates have been added yet. Templates are predefined formats for tasks and communications. Click the button below to generate initial templates.
                    </p>
                  </div>
                  <button @click="generateTemplate" class="btn bg-gradient-info">Generate Initial Template</button>
                </div>
              </div>
              <div class="container p-0 text-center d-none">
                <div class="row d-flex justify-content-center align-items-center mb-5 alert alert-info p-0">
                  <div class="col-12 col-md-6">
                    <div class="alert-icon">
                      <i class="fas fa-exclamation-triangle"></i>
                    </div>
                    <img
                        class="w-75 me-3 mb-0"
                        src="@/assets/img/illustrations/file-text-dynamic-gradient.png"
                        alt="logo"
                    />
                  </div>
                  <div class="col-12 col-md-6">
                    <h1 style="color: white">Provide Templates!</h1>

                    <h2 style="color: white">Create Templates for your users so they know what to ask</h2>
                    <p>
                      Give some examples to your users so they know how to use your expert. soon you will be able to connect an ability to the templates!
                    </p>

                  </div>
                  <div class="d-flex justify-content-around p-0">
                    <button class="btn btn-primary w-100 m-0" @click="toTemplateDoc()">
                      More Information
                    </button>
                  </div>
                </div>

              </div>
            </div>


            <div v-if="currentStep === 5">
              <Walkthrough
                  :steps="walkthroughStepsFifth"
                  :walktrough-activated="true"
              />
              <h1 class="text-center">
                Model Selection
                <DocumentationLink
                    popoverTitle="Model Selection"
                    class="z-index-5 documentation-link"
                    description="Learn how to add Templates to your Expert"
                    docLink="https://docs.b-bot.space/essentials/create-expert#choose-your-model"
                />
              </h1>

              <ExpertChooseModel :edit-mode="false" @to-next="currentStep ++" />
            </div>


            <div v-if="currentStep === 6">

              <ExpertCV :bbotMe="true" />

            </div>

          </div>
        </transition>


        <div class="row">
          <div class="col-md-8 mx-auto">
            <!-- Add this section for validation errors -->
            <div v-if="validationErrors.length" class="mt-4">
              <material-alert
                  v-for="(error, index) in validationErrors"
                  :key="index"
                  class="font-weight-light"
                  color="danger"
                  dismissible
                  @dismissed="clearValidationErrors"
              >
                <span class="text-md">{{ error.path }}</span>
                <span class="text-sm">{{ error.message }}</span>
              </material-alert>
            </div>
          </div>
        </div>

        <!-- Navigation Buttons -->
        <div class="navigation-buttons-container">
          <div class="navigation-buttons d-flex justify-content-between p-2">
            <button
                class="btn btn-secondary flex-fill me-2"
                @click="currentStep--"
                v-if="currentStep > 2"
            >
              Previous
            </button>

            <button v-if="currentStep === 6" type="submit" class="btn flex-fill bg-gradient-success shadow-success" @click="handleSubmit">
              Create
            </button>
            <button
                id="nextButton"
                class="btn btn-primary flex-fill"
                @click="handleNextStep"
                v-if="currentStep < 6"
            >
              {{ currentStep === 1 || currentStep === 5 ? 'Save and Proceed' : 'Next' }}
            </button>
          </div>
        </div>



      </div>
    </template>
  </VueFinalModal>
</template>

<script>
import {VueFinalModal} from "vue-final-modal";
import MaterialAvatar from "@/components/MaterialAvatar.vue";
import MaterialAlert from "@/components/MaterialAlert.vue";
import ExpertCV from "@/views/components/ExpertCV.vue";
import ExpertChooseModel from "@/views/components/ExpertChooseModel.vue";
import {mapActions, mapState} from "vuex";
import loadingChat from "@/assets/img/illustrations/loadingChat.json";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import DocumentationLink from "./DocumentationLink.vue";
import ExpertService from "@/classes/services/ExpertService";
//import {DatacenterService} from "@/classes/services/DatacenterService";
//const { VUE_APP_MAIN_API_URL } = process.env;
//export const datacenterService = new DatacenterService();
import {MainApiService} from "@/classes/services/MainApiService";
import Walkthrough from "@/views/components/Walkthrough.vue";
import {DatacenterService} from "@/classes/services/DatacenterService";
export const mainApiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);
export const datacenterService = new DatacenterService();

export default {
  components: {
    Walkthrough,
    DocumentationLink,
    MaterialSwitch,
    MaterialTextarea,
    ExpertChooseModel,
    MaterialAvatar,
    VueFinalModal,
    MaterialAlert,
    ExpertCV,
    MaterialInput
  },
  props: {
    initialExpert: Object,
  },
  data() {
    return {
      animationData: loadingChat,
      isVisible: false,
      isLoading: false,
      currentStep: 1,
      expertData: this.initializeExpertData(),
      transitionName: 'slide-fade',
      validationErrors: [],
      snackbar: null,
      proMode: false,
      isWalkthroughVisible: false,
      createNew: false,
      walkthroughStepsFirst: [
        {
          elementId: "name",
          title: "Step 1: Enter Name",
          description: "Provide your name or a name you really like into the input field.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-name.mp3")
          },
          links: [
            {
              text: "More Information",
              link: "https://docs.b-bot.space/essentials/create-expert#name",
            }
          ]
        },
        {
          elementId: "profession",
          title: "Step 2: Enter Profession",
          description: "Provide your name in the input field.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-profession.mp3")
          }
        },
        {
          elementId: "description",
          title: "Step 3: Enter Description",
          description: "Provide a description of you in this input field.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-description.mp3")
          }
        },
        {
          elementId: "proModeContainer",
          clickableElementId: "proMode",  // This is the element that will be clicked
          title: "Step 4: Enter Pro Mode",
          description: "If you are already an experienced user you can enter Pro Mode. This will give you more features and options to define your B-Bot Me's traits",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-pro-mode.mp3")
          }
        },
        {
          elementId: "systemMessageContainer",
          title: "Step 5: Enter the System Message",
          description: "Enter a System Message for your B-Bot Me. This will be the standard Instruction for the Agent to know as who he should act, its best to describe just a small amount of text as a personality instruction.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-system-message.mp3")
          }
        },
        {
          elementId: "functionNameContainer",
          title: "Step 6: Enter the Function Name",
          description: "Enter a Function Name for your B-Bot Me. This will be the Name for the other Virtual Experts (Agents) to know as who your B-Bot me is, its best to choose a short name, something like, ask peter the comedian.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-function-name.mp3")
          }
        },
        {
          elementId: "functionDescriptionContainer",
          title: "Step 7: Enter the Function Description",
          description: "Enter a Function Description for your B-Bot Me. This will be the Description for the other Virtual Experts (Agents) to know as what your B-Bot me can do or what knowledge he has, its best to choose a short, distinct description, something like: if you have to ask a comedian who knows good jokes.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-function-description.mp3")
          }
        },
        {
          elementId: "nextButton",
          title: "Step 8: Let's Go!",
          description: "Now if you are ready to create your B-Bot Me, you can proceed by clicking this button. Remember, if you are disabling the pro mode before clicking our AI Systems will do that job for you. But if you are into hard work, feel free to craft all of this text by your own.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-proceed-first-step.mp3")
          }
        },
        // Add more steps here...
      ],
      walkthroughStepsSecond:[
        {
          elementId: "uploadPictureContainer",
          title: "Step 9: Upload a Picture",
          description: "Upload a Picture of your B-Bot me by clicking on the little camera icon",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-profile-picture.mp3")
          }
        },
        {
          elementId: "nextButton",
          title: "Step 10: Lets get to the next step!",
          description: "If you like your Picture you can proceed by clicking this button. If you don't like your Picture you can click the camera icon again to upload a new one.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-proceed-second-step.mp3")
          }
        },
      ],
      walkthroughStepsThird:[
        {
          elementId: "generateInitialAbility",
          title: "Step 11: Add initial Ability",
          description: "Here we are forming the first personality traits for your B-Bot Me, this section allow you to generate Abilities for your Expert. They act like branches of behaviour that your B-Bot Me can adapt to. You can add as many Abilities as you want, but we recommend to start with 3-5. As usual we are supporting you with our AI Systems, so you can just click the button and let them do the rest.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-abilities-generate-initial.mp3")
          }
        },
        {
          elementId: "nextButton",
          title: "Step 12: Let's proceed!",
          description: "If you have generated an initial Ability the system let's you edit or delete them as you like. If you are ready to proceed click this button and i will take you to the next step.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-proceed-third-step.mp3")
          }
        },
      ],
      walkthroughStepsFourth:[
        {
          elementId: "generateInitialTemplate",
          title: "Step 13: Add initial Template",
          description: "Everybody knows a valuable conversation always starts with the right questions, by generating Templates you will be provided with conversation starters at the beginning of every Chat you will have with your B-Bot Me. Don't hesitate to add as many Templates as you want, but we recommend to start with 2-3. As usual the AI Systems are ready for you, so you can just click the button and let the robots do the work for you.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-generate-templates.mp3")
          }
        },
        {
          elementId: "nextButton",
          title: "Step 14: Let's proceed!",
          description: "If you have generated some conversation starters the system again, let's you edit or delete them as you like. If you are ready to proceed click this button and we will continue with the procedure together.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-procced-fourth-step.mp3")
          }
        },
      ],
      walkthroughStepsFifth:[
        {
          elementId: "ChooseModelContainer",
          title: "Step 15: Choose the right Model",
          description: "Now let's choose the correct Model (LLM) for your B-Bot ME, you might now some of them, if you need further assistance on how and when to choose what model look into our documentation, or contact us on our Whatsapp Community. heres a neat little link to it:",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-choose-model.mp3")
          }
        },
        {
          elementId: "nextButton",
          title: "Step 16: Let's proceed to the last step!",
          description: "we are done creating your First Virtual Expert, which will act as your \"B-Bot me\". You will be able to create more Virtual Experts as soon as we are done with the onboarding. If you are ready to proceed click this button and we will continue with the other steps, no worries, soon we are ready to rock the world of AI and Virtual Experts together.",
          media: {
            type: "audio",
            src: require("@/assets/audio/b-bot-me-proceed-fifth-step.mp3")
          }
        },
      ],
    };
  },
  computed: {
    ...mapState("experts", [
        'selectedExpert',
        'experts',
        'selectedModelForAgentCreation',
        'selectedProviderForAgentCreation'
    ]),
    ...mapState(['user', "color"])
  },
  methods: {
    ...mapActions('training',['updateModel']),
    ...mapActions("experts", [
        'fetchExperts',
        'createExpert',
        'addAbility',
        'removeAbility',
        'updateAbility',
        'setSelectedExpert',
    ]),
    async handleModelSave() {
      // Mapping of model names to provider-specific identifiers
      const modelIdentifierMap = {
        'GPT-4': {
          'OpenAI': '-', // Replace with actual identifier if available
          'Azure': 'gpt-4-0613',
          'TuneAI': '-'
        },
        'GPT-4o-mini': {
          'OpenAI': 'gpt-4o-mini-2024-07-18',
          'Azure': 'gpt-4o-mini-2024-07-18',
          'TuneAI': '-'
        },
        'GPT-4o': {
          'OpenAI': 'gpt-4o-2024-08-06',
          'Azure': 'gpt-4o-2024-08-06',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-0125': {
          'OpenAI': 'gpt-3.5-turbo-0125',
          'Azure': 'gpt-35-turbo-0125',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-0613': {
          'OpenAI': 'gpt-3.5-turbo-0613',
          'Azure': 'gpt-35-turbo-0613',
          'TuneAI': '-'
        },
        'GPT-3.5-turbo-1106': {
          'OpenAI': 'gpt-3.5-turbo-1106',
          'Azure': 'gpt-35-turbo-1106',
          'TuneAI': '-'
        },
        'Llama-3.1-8b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-8b-instruct'
        },
        'Llama-3.1-70b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-70b-instruct'
        },
        'Llama-3.1-405b-instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'meta/llama-3.1-405b-instruct'
        },
        'Mistral-Large2': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/mistral-large-2'
        },
        'Meta-Llama-3-8B-Instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/Meta-Llama-3-8B-Instruct'
        },
        'Meta-Llama-3-70B-Instruct': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/Meta-Llama-3-70B-Instruct'
        },
        'mixtral-8x7b-inst-v0-1-32k': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/mixtral-8x7b-inst-v0-1-32k'
        },
        'tune-wizardlm-2-8x22b': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/tune-wizardlm-2-8x22b'
        },
        'tune-mythomax-l2-13b': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/tune-mythomax-l2-13b'
        },
        'openrouter-goliath-120b-4k': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/openrouter-goliath-120b-4k'
        },
        'gemma-2-27b-it': {
          'OpenAI': '-',
          'Azure': '-',
          'TuneAI': 'rohan/gemma-2-27b-it'
        }
      };

      let modelData;

      // Get the model name and provider
      const modelName = this.selectedModelForAgentCreation.type;
      const provider = this.selectedProviderForAgentCreation;

      // Get the correct identifier for the provider
      const identifier = modelIdentifierMap[modelName][provider];

      // Prepare the model data with the identifier
      modelData = {
        identifier: identifier,
        provider: provider.toLowerCase(),
        base_model_type: modelName,
        multiplier: this.selectedModelForAgentCreation.multiplier,
      };

      // Get the model ID to update
      let modelId = this.selectedExpert.attributes.expert_llm_models[0].id;

      // Update the model with the new data
      await this.updateModel({modelId, modelData});

      // Move to the next step
      this.currentStep++;
    },
    initializeExpertData() {
      if (this.initialExpert) {
        return JSON.parse(JSON.stringify(this.initialExpert)); // Deep copy if initialExpert exists
      } else {
        // Default structure for a new expert
        return {
          attributes: {
            profile_picture: { data: { attributes: { formats: { small: { url: '' } } } } },
            name: '',
            description: '',
            profession: '',
            functionName: '',
            functionDescription: '',
            systemMessage: '',
            templates: [],
            abilities: [],
          },
        };
      }
    },
    async handleProfilePictureUpload(event) {
      this.isLoading = true;
      const file = event.target.files[0];
      console.log("selectedExpert: ", this.selectedExpert)
      if (file) {
        try {
          await this.selectedExpert.addProfilePicture(file);
          console.log("SELECTED EXPERT NOW AFTER UPLOADING IMAGE", this.selectedExpert)
          // Handle additional logic for the response if needed
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
      this.isLoading = false;
    },
    handleNextStep() {
      if (this.currentStep === 1 && !this.isEditMode) {
        this.saveNewExpert(); // Save the expert
      } else if(this.currentStep === 5) {
        this.handleModelSave()
      } else {
        this.currentStep++;
      }
    },
    async saveNewExpert() {
      this.isLoading = true;

      // Check if name, description, and profession are empty
      if (!this.expertData.attributes.name || !this.expertData.attributes.description || !this.expertData.attributes.profession) {
        alert("Name, Description, and Profession are required fields. Please fill them out.");
        this.isLoading = false;
        return;
      }
      // Check if description is less than 50 characters
      if (this.expertData.attributes.description.length < 50) {
        alert("Description must be at least 50 characters long.");
        this.isLoading = false;
        return;
      }

      // Confirmation before saving
      if (!confirm("Are you sure you want to save the Expert like that? The first Creation of the Expert signs the IDentification.")) {
        this.isLoading = false;
        return;
      }

      // Call APIs to generate system message and function description
      try {
        // Prepare request bodies for system message and function description
        if (!this.proMode) {
          const apiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

          try {
            // Generate system message
            const systemMessageResponse = await apiService.generateMessage('system message', this.expertData.attributes.description);
            this.expertData.attributes.systemMessage = await this.extractMessageContent(
                systemMessageResponse,
                `Act as ${this.expertData.attributes.name} hes a professional ${this.expertData.attributes.profession}`
            );
            console.log("this.expertData.attributes.systemMessage  ", this.expertData.attributes.systemMessage)
            // Generate function description
            const functionDescriptionResponse = await apiService.generateMessage(
                'function description',
                this.expertData.attributes.description
            );
            this.expertData.attributes.functionDescription = await this.extractMessageContent(
                functionDescriptionResponse,
                `if you have to ask ${this.expertData.attributes.name} hes a professional ${this.expertData.attributes.profession}`
            );

            // Generate function name
            const functionNameResponse = await apiService.generateMessage(
                'function name',
                this.expertData.attributes.functionDescription
            );
            this.expertData.attributes.functionName = await this.extractMessageContent(
                functionNameResponse,
                `ask ${this.expertData.attributes.name} a professional ${this.expertData.attributes.profession}`
            );
          } catch (error) {
            console.error('Error handling non-pro mode:', error.message);
            this.validationErrors.push({ path: 'API Error', message: error.message });
          }
        }

        // Construct the new expert data object
        const newExpertData = {
          name: this.expertData.attributes.name,
          description: this.expertData.attributes.description,
          profession: this.expertData.attributes.profession,
          author: 1, // Replace with the actual author ID when available
          system_message: this.expertData.attributes.systemMessage,
          function_description: this.expertData.attributes.functionDescription,
          function_name: this.expertData.attributes.functionName,
          abilities: [],
          templates: [],
          // Include other necessary attributes
        };

        // Call Vuex action to create the new expert
        const selectedExpertData = await ExpertService.saveExpert(newExpertData);
        console.log("SAVE NEW EXPERT SELECTED EXPERT", selectedExpertData)
        const newSelectedExpert = await ExpertService.getExpert(selectedExpertData.id)
        await this.setSelectedExpert(newSelectedExpert)
        console.log("SAVE NEW EXPERT SELECTED EXPERT", this.selectedExpert)
        const modifiedPayload = {
          name: "Indexer_of_" + this.selectedExpert.id,
          embedding_model: "openai/text-embedding-3-small",
          retriever_provider: "elastic-local",
          url: process.env.VUE_APP_ELASTICSEARCH_URL,
          user_id: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id,
          //user_name: process.env.VUE_APP_ELASTICSEARCH_USERNAME,
          //user_password: process.env.VUE_APP_ELASTICSEARCH_PASSWORD,
          graph_id: "indexer"
        };
        const responseData = await mainApiService.call(
            "create_memory_assistant",
            "POST",
            modifiedPayload,
        );
        console.log(responseData)
        const modifiedPayload2 = {
          name: "Retriever_of_" + this.selectedExpert.id,
          embedding_model: "openai/text-embedding-3-small",
          retriever_provider: "elastic-local",
          query_model: "openai/gpt-4o-2024-08-06",
          query_system_prompt: "you are a memory assistant, search in the documents for memories",
          response_model: "openai/gpt-4o-2024-08-06",
          response_system_prompt: "you are a memory assistant, search in the documents for memories",
          url: process.env.VUE_APP_ELASTICSEARCH_URL,
          user_id: this.user.sub.replace(/[|-]/g, '') + "_" + this.selectedExpert.id,
          //user_name: process.env.VUE_APP_ELASTICSEARCH_USERNAME,
          //user_password: process.env.VUE_APP_ELASTICSEARCH_PASSWORD,
          graph_id: "retrieval_graph"
        };
        const responseData2 = await mainApiService.call(
            "create_memory_assistant",
            "POST",
            modifiedPayload2,
        );
        const updatedApps = {
          memory: {
            tool_type: "memory",
            indexer: responseData.assistant_id,
            retriever: responseData2.assistant_id,
          }

        }
        console.log(responseData2)
        await this.selectedExpert.update(
            {
              attributes: {
                ...this.selectedExpert.attributes,
                version: "synapse",
                apps: {
                  ...this.selectedExpert.attributes.apps, // Existing apps
                  ...updatedApps, // New or updated apps
                }
              }
            }
        )
        await this.selectedExpert.save();
        // Clear any previous validation errors
        this.validationErrors = [];

        // Proceed to the next step on successful expert creation
        this.isLoading = false;
        this.currentStep++;
      } catch (error) {
        console.error('Error creating expert:', error);
        this.isLoading = false;
        // Handle error scenarios as needed
      }
    },
    async extractMessageContent(response, fallback) {
      console.log("THIS WAS THE RESPONSE", response)
      if (response && response.completion?.choices?.[0]?.message?.content) {
        console.log("response.completion.choices[0].message.content.substring(0, 255)", response.completion.choices[0].message.content.substring(0, 255))
        return response.completion.choices[0].message.content.substring(0, 255); // Truncate to 255 characters
      }
      return fallback;
    },
    async generateTemplate() {

      console.log("this.selectedExpert?.attributes?.templates: ", this.selectedExpert?.attributes?.templates)
      const apiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

      const functionNameResponse = await apiService.generateMessage(
          'questions',
          `generate the Question content of: ${this.selectedExpert.attributes.description}
          | these are the questions already generated: ${this.selectedExpert?.attributes?.templates?.map(ability => ability.attributes.template_text).join(', ')}`
      );
      let messageContent = await this.extractMessageContent(
          functionNameResponse,
          `Please Edit this template`
      );


      const newTemplate = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        template_name: 'Template', // The name or title of the template.
        template_text: messageContent,
        isEditing: true, // Indicates whether this ability is currently being edited.
        experts: this.selectedExpert.id
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.selectedExpert.attributes.templates) {
        this.selectedExpert.attributes.templates = [];
      }

      if (!Array.isArray(this.selectedExpert.attributes.templates)) {
        this.selectedExpert.attributes.templates = [];
      }
      console.log(newTemplate)
      await this.selectedExpert.addTemplate(newTemplate);

    },
    async generateAbility() {
      console.log("this.selectedExpert?.attributes?.abilities: ", this.selectedExpert?.attributes?.abilities)
      const apiService = new MainApiService(process.env.VUE_APP_MAIN_API_URL);

      const functionNameResponse = await apiService.generateMessage(
          'ability',
          `generate the Ability content of: ${this.selectedExpert.attributes.description}
          | these are the abilities already generated: ${this.selectedExpert?.attributes?.abilities?.map(ability => ability.attributes.text).join(', ')}`
      );
      let messageContent = await this.extractMessageContent(
          functionNameResponse,
          `Please Edit this ability`
      );

      const newAbility = {
        id: null, // A unique identifier for the ability, usually provided by the backend.
        name: 'Ability', // The name or title of the ability.
        text: messageContent, // A description of what the ability entails.
        isEditing: true, // Indicates whether this ability is currently being edited.
        experts: this.selectedExpert.id
      };

      // Ensure that this.expertData.attributes.abilities and its data property are initialized
      if (!this.selectedExpert.attributes.abilities) {
        this.selectedExpert.attributes.abilities = [];
      }

      if (!Array.isArray(this.expertData.attributes.abilities)) {
        this.selectedExpert.attributes.abilities = [];
      }

      console.log("newAbility",newAbility)
      this.selectedExpert.addAbility(newAbility)
      console.log(newAbility)
    },
    openModal() {
      this.currentStep = 1;
      this.isVisible = true;
    },
    closeModal() {
      this.isVisible = false;
    },
    async handleSubmit(with_close = true) {
      console.log("handle Submit")
      const me_id =  this.selectedExpert.id
      const me_key =  "mexpert_id"
      this.updateUserMetadata(me_key, me_id);

      console.log("this.user: ", this.user)
      this.selectedExpert.save()
      if (with_close){
        this.closeModal();
      }
      //await this.$router.push('/news'); // Or the next desired route
    },
    updateUserMetadata(metadataKey, newValue) {
      // Dispatch the Vuex action
      this.$store.dispatch('updateUserMetadata', { metadataKey, newValue });
    },
    async saveTemplate(template) {
      template.isEditing = false;
      console.log(template)
      template.update(template)
      template.save()
    },
    async deleteTemplate(templateIndex) {
      // Optional: Confirm with the user before deletion
      if (!confirm("Are you sure you want to delete this template?")) {
        return;
      }

      const template = this.selectedExpert.attributes.templates[templateIndex];
      await this.selectedExpert.removeTemplate(template)
    },
    async handleSaveAbility(ability) {
      console.log("handleSaveAbility",ability)
      await ability.update(ability)
      await ability.save();
    },
    async deleteAbility(abilityIndex) {
      if (!confirm("Are you sure you want to delete this ability?")) {
        return;
      }

      const ability = this.selectedExpert.attributes.abilities[abilityIndex];
      await this.selectedExpert.removeAbility(ability)
      //await this.expertData.removeAbility(ability);
    },
    // Method to clear validation errors, could be triggered by user actions or before a new validation
    clearValidationErrors() {
      this.validationErrors = [];
    },
    async toTemplateDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
    },
    async toAbilitiesDoc() {
      // Handle navigation to the template documentation page
      // You can use Vue Router to navigate to the desired page
    },
  },
  watch: {
    currentStep(newVal, oldVal) {
      this.transitionName = newVal > oldVal ? 'slide-fade' : 'slide-fade-reverse';
    },
    initialExpert: {
      handler() {
        this.expertData = this.initializeExpertData(); // Reinitialize expertData when initialExpert changes
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>


<style scoped>
.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content-card {
  width: 90%;
  max-width: 600px;
  margin: auto;
  overflow: hidden; /* Prevents content from overflowing the modal */
}

.modal-fullscreen {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.modal-scrollable-content {
  padding: 15px;
  overflow-y: auto; /* Enables scrolling for the content area */
  overflow-x: hidden;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.card-header-top{
  border-radius: 0 0 0.75rem 0.75rem !important;
}

.card-body, .card-footer {
  padding: 1rem;
}

.btn-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.mr-button{
  margin-right: 1rem;
}


.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


.navigation-buttons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.navigation-buttons {
  pointer-events: auto;
  width: 100%;
  background: white;
}

/* Slide-fade transition for entering and leaving */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* leave-to for <= Vue 2.1.8 */ {
  transform: translateX(-100%);
  opacity: 0;
}

/* Backward transition */
.slide-fade-reverse-enter-active, .slide-fade-reverse-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.slide-fade-reverse-enter, .slide-fade-reverse-leave-to /* leave-to for Vue 2.1.8 and below */ {
  transform: translateX(100%);
  opacity: 0;
}
.icon-steps{
  position: absolute;
  top: 0.7rem;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.7rem;
  margin-right: 1rem;
  color: white;
}

.confirm-modal {
  overflow-y: scroll !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000 !important;
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}


.confirm-modal >>> .confirm-modal-content {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: #fff;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}


.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.dark .confirm-modal-content {
  background: #000;
}
.active .card{
  border: 3px solid green;
}
.card-header{
  padding: .5rem 1rem;
  border-radius: .75rem .75rem .75rem .75rem !important;
}

.steps-indicator{
  margin-bottom: 2rem;
}
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2em;
  z-index: 9999;
}

.dark-version .vfm--overlay {
  background: black;
}

.dark-version .vfm--content {
  background: black;
}
.camera-spaces{
  margin: 0;
  padding: 0 6px;
}
.camera-fontsize{
  font-size: 0.6rem;
}
.documentation-link {
  display: inline; /* Ensure the icon appears inline */
  vertical-align: middle; /* Align vertically with the text */
  margin-left: 0px; /* Space between title and icon */
}

</style>
