<template>
  <!-- Added min-vh-100 and d-flex with align-items-center for desktop vertical centering -->
  <div class="container-fluid onboarding min-vh-100 d-flex align-items-center py-4 px-3">
    <!-- Wrapped all content in a container div that takes full width -->
    <div class="w-100">
      <div v-if="!user.hub_user_metadata.mexpert_id" class="row g-4">
        <!-- Video Section -->
        <div class="col-12 col-lg-6">
          <div class="ratio ratio-16x9 h-100">
            <iframe
                class="rounded-3 shadow-lg"
                src="https://www.youtube.com/embed/zJ5VvpPMN0A?si=OYBoR9vsYqDONdtp&amp;controls=0&amp;modestbranding&amp;rel=0"
                allowfullscreen
                title="B-Bot Introduction Video">
            </iframe>
          </div>
        </div>

        <!-- Welcome Card -->
        <div class="col-12 col-lg-6">
          <div class="card shadow-lg rounded-3 h-100">
            <div class="card-body p-4">
              <h1 class="h2 mb-4">Welcome to B-Bot!</h1>
              <p class="lead mb-4">
                Meet your <strong>B-Bot Me</strong> — a Digital Twin designed to represent you on this platform.
                It will act as your personal coach, guiding users with your expertise, knowledge, and personality.
              </p>
              <p class="text-muted mb-4">
                By creating your B-Bot Me, you'll define its unique traits, such as its name, coaching style, and
                areas of expertise. Once set up, it will be ready to assist others and showcase your skills!
              </p>
              <button
                  class="btn btn-primary w-100 w-md-auto"
                  @click="openCreateModal">
                Start Experience
              </button>
            </div>
          </div>
        </div>


      </div>

      <!-- No Subscription Section -->
      <div
          v-if="user.hub_user_metadata.mexpert_id && !abo.hasSubscription"
          class="bg-light rounded shadow-sm p-4"
      >
        <div class="text-center mb-4">
          <h5 class="text-danger mb-3">
            <span class="material-icons align-middle">warning</span>
            No Active Subscription
          </h5>
          <p class="text-secondary">
            The user has no active subscription but has a mexpert_id.
          </p>
        </div>

        <!-- Subscriptions -->
        <div>
          <h6 class="text-primary mb-4 text-center">
            <span class="material-icons align-middle">subscriptions</span>
            Available Subscriptions
          </h6>
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
            <div
                v-for="sub in subs.abos"
                :key="sub.priceId"
                class="col"
            >
              <div class="card h-100 shadow-sm border-0">
                <div class="card-body">
                  <h6 class="card-title text-dark mb-2">
                    <span class="material-icons align-middle text-success me-2">check_circle</span>
                    {{ sub.productName }}
                  </h6>
                  <p class="card-text text-muted">{{ sub.description }}</p>
                  <div class="mt-3">
                    <span class="badge bg-info text-dark">
                      {{ sub.currency.toUpperCase() }} {{ sub.amount }} /
                      {{ sub.interval ? sub.interval : "one-time" }}
                    </span>
                  </div>
                </div>
                <div class="card-footer bg-light border-0">
                  <a
                      :href="sub.buyLink"
                      class="btn btn-outline-primary w-100"
                  >
                    <span class="material-icons align-middle me-1">shopping_cart</span>
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Success State -->
      <div v-else-if="user.hub_user_metadata.mexpert_id" class="card p-4 mx-auto" style="max-width: 500px;">
        <div class="text-center">
          <i class="material-icons text-success mb-3" style="font-size: 48px;">check_circle</i>
          <h5 class="mb-3">You're All Set! 🎉</h5>
          <div class="text-muted mb-4">
            You have completed all necessary steps:
            <div class="d-flex flex-column gap-2 align-items-start mx-auto mt-3" style="max-width: 300px;">
              <div><i class="material-icons text-success me-2">check</i>Active subscription</div>
              <div><i class="material-icons text-success me-2">check</i>Onboarding complete</div>
              <div><i class="material-icons text-success me-2">check</i>B-Bot ME created</div>
            </div>
          </div>
          <button
              class="btn btn-success w-100"
              @click="completeOnboarding">
            Complete Onboarding
            <i class="material-icons ms-2">arrow_forward</i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Expert Create Modal -->
  <ExpertCreateModalOnboarding ref="expertCreateModal" :initialExpert="selectedExpert" />
</template>
<script>
import {mapActions, mapMutations, mapState} from "vuex";
import ExpertCreateModalOnboarding from "@/views/components/ExpertCreateModalOnboarding.vue";

export default {
  data(){
    return{
      selectedExpert: {},
      subs: {}
    }

  },
  components: {ExpertCreateModalOnboarding},
  computed: {
    ...mapState(['user', 'abo']),
    ...mapState("experts"),
  },
  methods: {
    ...mapMutations(["hideEveryDisplay", "hideConfig"]),
    ...mapActions("experts", ["setSelectedExpert"]),
    ...mapActions(["setAbo"]),
    openCreateModal() {
      this.isEditMode = false;
      this.selectedExpert = null; // Reset or set default values for new expert
      this.$refs.expertCreateModal.openModal();
    },
    async checkSubscription() {
      try {
        console.log("checkSubscription", this.user.email)
        const email = this.user.email;
        const response = await fetch('/.netlify/functions/checkStripeAbo', {
          method: 'POST',
          body: JSON.stringify({ email }),
        });

        // Parse the JSON response
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error checking subscription:', error);
        throw error;
      }
    },
    async getSubscriptions() {
      try {
        const response = await fetch('/.netlify/functions/getStripeSubscriptions', {
          method: 'GET', // Correct HTTP method
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parse the JSON response
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        throw error;
      }
    },
    async completeOnboarding() {
      try {
        // Mark onboarding as complete in the store
        await this.updateUserMetadata("completed_onboarding", true);
        console.log("completed onboarding");

        // Get updated user data
        await this.$store.dispatch('getUser');

        // Check if experts exist before trying to set one
        if (this.experts && this.experts.length > 0) {
          await this.setSelectedExpert(this.experts[0]);
        } else {
          console.warn('No experts available to select');
        }

        // Navigate to news page
        await this.$router.push("/news");
      } catch (error) {
        console.error('Error completing onboarding:', error);
        // Handle the error appropriately - maybe show a notification to the user
      }
    },
    async updateUserMetadata(metadataKey, newValue) {
      // Dispatch the Vuex action
      await this.$store.dispatch('updateUserMetadata', { metadataKey, newValue });
    },
  },
  async mounted() {
    await this.hideEveryDisplay();
    await this.hideConfig();
    this.$store.state.showNavbar = false;
    console.log(this.user)
    let abo = await this.checkSubscription(this.user.email);
    await this.setAbo(abo)
    this.subs = await this.getSubscriptions();
  }
};
</script>

<style>
/* Add styles for your onboarding layout */
.onboarding {
  padding: 20px;
  text-align: center;
}
.embed-responsive-item{
  width: 100%;
  height: 400px;
}
</style>
