<template>
  <div v-if="walktroughActivated">
    <div v-if="isVisible" class="walkthrough-container">
      <div v-if="currentStep < steps.length"
           :class="['instruction-drawer', 'card', 'shadow-lg', minimalMode ? 'minimal' : '', isMobile ? 'bottom-mobile' : '']">
        <!-- Close button -->
        <div class="close-button" @click="closeWalkthrough">
          <span class="material-icons">close</span>
        </div>

        <div class="card-body pt-3">
          <!-- Collapsible section -->


        <!-- Navigation controls -->

          <div v-if="!minimalMode && steps[currentStep].media" class="media-container mb-1 mt-3">

            <template v-if="steps[currentStep].media.type === 'audio'">
              <audio ref="media" :src="steps[currentStep].media.src" controls autoplay class="w-100" />
            </template>
            <template v-else-if="steps[currentStep].media.type === 'video'">
              <video ref="media" :src="steps[currentStep].media.src" controls autoplay class="w-100 border-radius-lg" />
            </template>

          </div>
          <div class="navigation-controls">
            <button @click="prevStep" :disabled="currentStep === 0" class="nav-btn">
              <span class="material-icons">chevron_left</span>
            </button>
            <button @click="togglePlayPause" class="nav-btn d-none">
            <span class="material-icons">
              {{ isPlaying ? 'pause' : 'play_arrow' }}
            </span>
            </button>
            <button @click="nextStep" :disabled="currentStep === steps.length - 1" class="nav-btn">
              <span class="material-icons">chevron_right</span>
            </button>
          </div>
          <div class="content-header" @click="toggleContent">
            <span class="material-icons">
              {{ isContentVisible ? 'expand_less' : 'expand_more' }}
            </span>
          </div>

          <div v-show="isContentVisible">
            <h3 class="card-title">{{ steps[currentStep].title }}</h3>

            <p class="card-text" v-if="!minimalMode">{{ steps[currentStep].description }}</p>
            <div v-if="steps[currentStep].links && steps[currentStep].links.length" class="links-container mt-2">
              <a
                  v-for="(link, index) in steps[currentStep].links"
                  :key="index"
                  :href="link.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary btn-sm w-100"
              >
                <span class="link-text">{{ link.text }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isVisible" class="walkthrough-icon" @click="toggleMode">
      <span class="material-icons">menu_book</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Walkthrough",
  props: {
    walktroughActivated: Boolean,
    steps: {
      type: Array,
      required: true,
      validator(steps) {
        return steps.every(step => step.elementId && step.title);
      }
    }
  },
  data() {
    return {
      currentStep: 0,
      minimalMode: false,
      isVisible: true,
      isContentVisible: false,
      isPlaying: false,
      playbackInterval: null,
      elementClickHandlers: new Map(),
      audioPlaying: false,
      globalClickHandler: null

    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    currentMediaElement() {
      return this.$refs.media;
    },
    hasAudioInCurrentStep() {
      return this.steps[this.currentStep]?.media?.type === 'audio';
    }
  },
  watch: {
    currentStep(newStep) {
      this.highlightElement(newStep);
      this.handleMediaPlayback();
    }
  },
  methods: {
    initializeGlobalClickHandler() {
      this.globalClickHandler = (event) => {
        const clickedElement = event.target;
        // Find the step that contains this element or its parents
        const stepIndex = this.findStepIndexByElement(clickedElement);
        if (stepIndex !== -1) {
          this.currentStep = stepIndex;
        }
      };
      document.addEventListener('click', this.globalClickHandler);
    },
    findStepIndexByElement(element) {
      // Check the clicked element and its parents against all step elements
      let currentElement = element;
      while (currentElement) {
        for (let i = 0; i < this.steps.length; i++) {
          if (currentElement.id === this.steps[i].elementId) {
            return i;
          }
        }
        currentElement = currentElement.parentElement;
      }
      return -1;
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.pauseCurrentMedia();
        this.currentStep++;
      }
    },
    prevStep() {
      if (this.currentStep > 0) {
        this.pauseCurrentMedia();
        this.currentStep--;
      }
    },
    togglePlayPause() {
      if (this.hasAudioInCurrentStep && this.currentMediaElement) {
        if (this.audioPlaying) {
          this.pauseCurrentMedia();
        } else {
          this.playCurrentMedia();
        }
      } else {
        // Fall back to step auto-advancement if no audio
        this.isPlaying = !this.isPlaying;
        if (this.isPlaying) {
          this.playbackInterval = setInterval(() => {
            if (this.currentStep < this.steps.length - 1) {
              this.nextStep();
            } else {
              this.isPlaying = false;
              clearInterval(this.playbackInterval);
            }
          }, 5000);
        } else {
          clearInterval(this.playbackInterval);
        }
      }
    },
    playCurrentMedia() {
      if (this.currentMediaElement) {
        this.currentMediaElement.play().then(() => {
          this.audioPlaying = true;
        }).catch(error => {
          console.error('Error playing media:', error);
        });
      }
    },
    pauseCurrentMedia() {
      if (this.currentMediaElement) {
        this.currentMediaElement.pause();
        this.audioPlaying = false;
      }
    },
    handleMediaPlayback() {
      const mediaElement = this.currentMediaElement;
      if (mediaElement) {
        mediaElement.pause();
        mediaElement.currentTime = 0;
        this.audioPlaying = false;

        // Add event listeners for the current media element
        mediaElement.addEventListener('play', () => {
          this.audioPlaying = true;
        });
        mediaElement.addEventListener('pause', () => {
          this.audioPlaying = false;
        });
        mediaElement.addEventListener('ended', () => {
          this.audioPlaying = false;
        });
      }
    },
    closeWalkthrough() {
      this.isVisible = false;
      this.removeAllClickHandlers();
      this.removePreviousHighlights();
    },
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    },
    findStepIndexByElementId(elementId) {
      return this.steps.findIndex(step => step.elementId === elementId);
    },
    addClickHandler(element, stepIndex) {
      const handler = () => {
        this.currentStep = stepIndex;
      };
      element.addEventListener('click', handler);
      this.elementClickHandlers.set(element, handler);
    },
    removeAllClickHandlers() {
      this.elementClickHandlers.forEach((handler, element) => {
        element.removeEventListener('click', handler);
      });
      this.elementClickHandlers.clear();
    },
    highlightElement(stepIndex) {
      this.removePreviousHighlights();
      const step = this.steps[stepIndex];
      const element = document.getElementById(step.elementId);
      if (element) {
        this.scrollElementIntoView(element);
        element.classList.add('highlighted');

        // Add click handler for the highlighted element
        const elementStepIndex = this.findStepIndexByElementId(step.elementId);
        if (elementStepIndex !== -1) {
          this.addClickHandler(element, elementStepIndex);
        }
      }
      if (step.clickableElementId) {
        const clickableElement = document.getElementById(step.clickableElementId);
        if (clickableElement && typeof clickableElement.click === 'function') {
          clickableElement.click();
        }
      }
    },
    removePreviousHighlights() {
      this.removeAllClickHandlers();
      const highlightedElements = document.querySelectorAll('.highlighted');
      highlightedElements.forEach(el => el.classList.remove('highlighted'));
    },
    scrollElementIntoView(element) {
      const rect = element.getBoundingClientRect();
      if (rect.top < 0 || rect.bottom > window.innerHeight) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    },
    toggleMode() {
      this.removePreviousHighlights()
      this.isVisible = !this.isVisible;
    },
  },
  mounted() {
    if (this.isMobile) {
      this.minimalMode = true;
    }
    this.highlightElement(this.currentStep);
    this.initializeGlobalClickHandler();

  },
  beforeUnmount() {
    if (this.playbackInterval) {
      clearInterval(this.playbackInterval);
    }
    this.pauseCurrentMedia();
    this.removeAllClickHandlers();
    if (this.globalClickHandler) {
      document.removeEventListener('click', this.globalClickHandler);
    }
  }
};
</script>

<style scoped>
.instruction-drawer {
  position: fixed;
  top: 0;
  right: 40vw;
  width: 250px; /* Made more compact */
  z-index: 1050;
  background: white;
  border-radius: 0 0 1rem 1rem;
  padding: 0 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  z-index: 1000;
}

.content-header {
  cursor: pointer;
  padding: 5px;
  text-align: center;
}

.navigation-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5px;
}

.nav-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.nav-btn .material-icons {
  font-size: 20px;
}

.minimal .card-body {
  padding: 5px;
}

.walkthrough-icon {
  position: fixed;
  top: 0px;
  right: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  width: 35px;
  height: 35px;
  border-radius: 0 0 20px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.material-icons {
  font-size: 18px;
}

.card-body {
  padding: 5px;
}

.card-title {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .instruction-drawer {
    width: calc(100% - 40px);
    top: 0;
    right: 20px;
    padding: 0;
  }

  .minimal {
    font-size: 14px;
  }
}

</style>